<template>
  <div id="app" class="flex flex-col bg-[#f2f5f7] ">
    <nav class="flex items-center justify-between flex-wrap bg-white p-4">
      <img src="@/assets/images/logo.png" alt="Logo Left" class="object-cover w-36 h-auto mt-4 " />
 
     
    </nav>

    <main class=" md:container md:mx-auto text-contentTextColor md:px-6 px-2 py-8   pb-[2rem]">
      <router-view :key="$route.fullPath" />
    </main>

    <footer class=" bg-[#2783c5] text-white py-5 shadow-lg ">
      <div class="container mx-auto text-center text-gray-200 h-12 flex justify-center items-center">
        © 2024 Your Company. All rights reserved.
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  mounted() {
    // Retrieve the selected locale from local storage when the component is mounted
  },
  watch: {
    getIsChanged(newValue, oldValue) {
      console.log(`Message changed from ${newValue} to ${oldValue}`)
    }
  },

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');

*:focus {
  outline: none !important;
}

.bg-gredient {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 67, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
}

/* Define your color scheme here */
:root {
  --navColor: #1E293B;
  --footerColor: #334155;
  --mainContentColor: #F1F5F9;
  --contentTextColor: #111827;
}


#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

header,
footer {
  text-align: center;
}


</style>

