import { createRouter, createWebHistory } from 'vue-router'
const SubscribeView = () => import(/* webpackChunkName: "SubscribeView" */ '../views/SubscribeView.vue');
const ConfirmOtp = import(/* webpackChunkName: "about" */ '../views/ConfirmOtp.vue');
const SuccessPage = import(/* webpackChunkName: "about" */ '../views/SuccessPage.vue');


const routes = [
  {
    path: '/',
    name: 'home',
    component: SubscribeView,
  },
  {
    path: '/verify-otp',
    name: 'verify-otp',
    component: () => ConfirmOtp,

  },
  {
    path: '/success',
    name: 'success',
    component: () => SuccessPage,

  }

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
